<template>
	<v-text-field
		:value="copiedValue"
		:append-icon="appendIcon"
		:clearable="clearable"
		:disabled="disabled"
		:label="labelText"
		:loading="loading"
		:maxlength="maxlength"
		:prepend-inner-icon="prependIcon"
		:readonly="readonly"
		:rules="inputRules"
		:single-line="singleLine"
		:style="width ? `width:${width}` : ''"
		:type="type"
		v-bind="$attrs"
		v-on="$listeners"
	/>
</template>

<script>
import Validator from '@/mixins/Validator'

/**
 * @displayName w-text-input
 *
 * @since 0.3.2
 */
export default {
	name: 'WTextInput',
	mixins: [Validator],
	props: {
		appendIcon: {
			required: false,
			type: String,
			default: ''
		},
		clearable: {
			required: false,
			type: Boolean,
			default: false
		},
		disabled: {
			required: false,
			type: Boolean,
			default: false
		},
		disabledRules: {
			default: () => ([]),
			required: false,
			type: Array
		},
		label: {
			required: false,
			type: String,
			default: null
		},
		loading: {
			required: false,
			type: Boolean,
			default: false
		},
		maxlength: {
			required: false,
			type: [Number, String],
			default: null
		},
		prependIcon: {
			required: false,
			type: String,
			default: ''
		},
		readonly: {
			required: false,
			type: Boolean,
			default: false
		},
		singleLine: {
			required: false,
			type: Boolean,
			default: false
		},
		type: {
			required: false,
			type: String,
			default: 'text'
		},
		regex: {
			required: false,
			type: String,
			default: null
		},
		required: {
			required: false,
			type: Boolean,
			default: false
		},
		value: {
			required: false,
			type: [Number, String],
			default: ''
		},
		width: {
			default: undefined,
			required: false,
			type: String
		}
	},
	computed: {
		copiedValue: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		},
		inputRules: function () {
			let result = []
			if (this.$attrs.hasOwnProperty('rules') && Array.isArray(this.$attrs.rules)) {
				this.$attrs.rules.forEach(rule => {
					result.push(rule)
				})
			}
			if (this.regex) {
				result.push(value => {
					return this.validateRegex(this.regex, value) ? true : this.$t('rules.regex')
				})
			}
			if (this.required) {
				result.push(this.rules.required)
			}
			if (this.type === 'email') {
				result.push(this.rules.email)
			} else if (this.type == 'url' && !this.disabledRules.includes('url')) {
				result.push(this.rules.url)
			}
			return result
		},
		labelText: function () {
			if (this.label) {
				if (this.required) {
					return this.label + '*'
				}
				return this.label
			}
			return null
		}
	}
}
</script>
